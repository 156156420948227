/* eslint-disable no-case-declarations */
import * as yup from 'yup';
import { ListObject, ListForm } from './types';
import { CustomObjectProperty } from '../CustomObjects/types';
import dayjs from 'dayjs';

export const formSchema = yup
    .object({
        listName: yup.string().trim().min(2, 'Name must be at least 2 characters').required(),
        backofficeManaged: yup.boolean().typeError('backoffice managed is required').required(),
        listType: yup.string().trim().min(2, 'List Type is required').required()
    } as Record<keyof ListForm, any>)
    .required();

export const normalizeDataToForm = ({ listName, description, enabled, objectDefinition, ...list }: ListObject) => ({
    listName,
    description,
    enabled,
    backofficeManaged: list.origin === 'BackOffice',
    listType: list.dataType,
    objectDefinitionId: list.dataType === 'Object' ? objectDefinition?.id : undefined
});

export const defaultValues: ListForm = {
    listName: '',
    description: '',
    listType: '',
    backofficeManaged: false,
    enabled: true
};

export const dayJSFormatForRawDate = 'MM/DD/YYYY';
export const dayJSFormatForRawDateTime = 'MM/DD/YYYY HH:mm';

export const validateImportedValue = (value: any, isRequired: boolean, fieldName: string, dataType: string) => {
    // Empty required field
    if (isRequired && !value) {
        return { error: true, message: `${fieldName} is required` };
    }
    // Empty non required field
    if (!isRequired && !value) {
        return { error: false, message: `` };
    }
    // Validate field datatype
    switch (dataType) {
        case 'number':
            return !isNaN(Number(value)) ? { error: false, message: `` } : { error: true, message: `${fieldName} must be a number` };
        case 'date':
            return dayjs(value, dayJSFormatForRawDate, true).isValid()
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} Invalid date format, format should be MM/DD/YYYY` };
        case 'datetime':
            return dayjs(value, dayJSFormatForRawDateTime, true).isValid()
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} Invalid datetime format, format should be MM/DD/YYYY HH:mm (24 hours)` };
        case 'checkbox':
            return ['Yes', 'No'].includes(value)
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} must be Yes or No` };
        default:
            return { error: false, message: `` };
    }
};

export const mapImportedValueToPreview = (value: any, dataType: string) => {
    if (!value) return value;
    switch (dataType) {
        case 'number':
            return +value;
        case 'date':
            return dayjs(value, dayJSFormatForRawDate).format('YYYY-MM-DD');
        case 'datetime':
            return dayjs(value, dayJSFormatForRawDateTime).utc().format();
        case 'checkbox':
            return value === 'Yes';
        default:
            return value;
    }
};

export const parseImportedValue = (value: any, dataType: string) => {
    if (!value) return value;
    switch (dataType) {
        case 'number':
            return +value;
        case 'date':
            return dayjs(value.substring(0, 10).trim(), dayJSFormatForRawDate).format(dayJSFormatForRawDate);
        case 'datetime':
            const dateSplitted = value.split(' ');
            const timeSplitted = dateSplitted[1].split(':');
            const parsedDateTime = dayjs(dateSplitted[0], dayJSFormatForRawDate).hour(+timeSplitted[0]).minute(+timeSplitted[1]);
            return parsedDateTime.format(dayJSFormatForRawDateTime);
        default:
            return value;
    }
};

export const mapObjectPropertiesWithExcelKeys = (objectProperties: CustomObjectProperty[], sheetData: any) =>
    objectProperties.map((prop) => {
        const propMapped: CustomObjectProperty & { excelKey?: string } = { ...prop };
        Object.keys(sheetData).forEach((key) => {
            if (prop.name.trim() === sheetData[key].replaceAll('*', '').trim()) {
                propMapped.excelKey = key;
            }
        });
        return propMapped;
    });

export const mapAndValidateImportedValues = (sheetData: any, objectPropertiesMapped: any) => {
    const newValues: any[] = [];
    const validation: { error: boolean; message: string }[] = [];
    sheetData.forEach((sData: any, index: number) => {
        if (index > 0) {
            const newRow = [...objectPropertiesMapped].map((prop) => {
                const propValidation = validateImportedValue(
                    ['date', 'datetime'].includes(prop.dataType)
                        ? parseImportedValue(sData[prop.excelKey ?? ''], prop.dataType)
                        : sData[prop.excelKey ?? ''],
                    prop.isRequired,
                    prop.name,
                    prop.dataType
                );
                if (propValidation.error) throw new Error(propValidation.message);
                validation.push(propValidation);
                return {
                    ...prop,
                    value: parseImportedValue(sData[prop.excelKey ?? ''], prop.dataType) ?? ''
                };
            });
            newValues.push(newRow);
        }
    });
    return { newValues, validation };
};
