import React from 'react';
import { BreadcrumbsItem } from '../types';
import { Breadcrumbs, Link, Typography } from '@mui/material';

export type BreadcrumbsProps = {
    items: BreadcrumbsItem[];
};

export const GridLevelBreadcrumbs = ({ items }: BreadcrumbsProps) => (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
        {items.map(({ label, onClick }, index) =>
            index === items.length - 1 ? (
                <Typography key={index} variant="h5" className="MuiCardHeader-title">
                    {label}
                </Typography>
            ) : (
                <Link
                    key={index}
                    component={Typography}
                    variant="h5"
                    underline="hover"
                    color="inherit"
                    onClick={onClick}
                    sx={{ cursor: 'pointer' }}
                    className="MuiCardHeader-title"
                >
                    {label}
                </Link>
            )
        )}
    </Breadcrumbs>
);
