/* eslint-disable arrow-body-style */
import { AddCircleOutline, Computer } from '@mui/icons-material';
import { Grid, OutlinedInput, InputAdornment, Button, IconButton, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { IconSearch } from '@tabler/icons';
import React, { ChangeEvent } from 'react';
import { preferenceList } from '../mock';

export type OptionsBannerProps = {
    name?: string;
    inputValue: string;
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    showAdd: boolean;
    onAdd: () => void;
};

export const OptionsBanner = ({ name, inputValue, onSearchChange, onAdd, showAdd }: OptionsBannerProps) => {
    return (
        <Grid container alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }} spacing={2} sx={{ my: 1 }}>
            <Grid item xs={11} md="auto">
                <OutlinedInput
                    fullWidth
                    sx={{ pt: '5px', pb: '5px', maxWidth: { md: '300px' } }}
                    id="input-quick-search"
                    placeholder={`Find in ${name?.toLowerCase() || ''}`}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="16px" />
                        </InputAdornment>
                    }
                    size="small"
                    value={inputValue}
                    onChange={onSearchChange}
                />
            </Grid>

            <Grid item container xs={12} md="auto" justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center" spacing={2}>
                <Grid item xs={11} md="auto">
                    <FormControl sx={{ width: { xs: '100%', md: '230px' }, maxWidth: { xs: '100%', md: '230px' } }}>
                        <InputLabel id="preference-select-label">Grid Preferences</InputLabel>
                        <Select
                            labelId="preference-select-label"
                            data-testid="preference-select"
                            label="Grid Preferences"
                            defaultValue={preferenceList[1].savedName}
                            sx={{
                                maxHeight: '50px',
                                '& > .MuiSelect-select': {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    '& > span': {
                                        display: 'block',
                                        width: '80%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    },
                                    '& > span#controlButtons': {
                                        display: 'block !important',
                                        textAlign: 'right',
                                        '& > button:hover': {
                                            backgroundColor: '#203461 !important',
                                            color: '#FFF'
                                        }
                                    }
                                }
                            }}
                        >
                            {preferenceList.map((preference) => (
                                <MenuItem
                                    key={preference.id}
                                    value={preference.savedName}
                                    sx={{
                                        justifyContent: 'space-between',
                                        '& > span': {
                                            display: 'block',
                                            width: '80%',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }
                                    }}
                                >
                                    <span>{preference.savedName}</span>

                                    {preference.role && <Computer color="secondary" />}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md="auto" sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="secondary" sx={{ width: '150px', height: '40px' }}>
                        Save Changes
                    </Button>
                </Grid>

                <Grid item xs={6} md="auto" sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="secondary" sx={{ width: '150px', height: '40px' }}>
                        Save as New
                    </Button>
                </Grid>
            </Grid>
            {showAdd && (
                <Grid item xs={1} sx={{ textAlign: { md: 'right' }, ml: { md: 'auto' } }}>
                    <IconButton color="secondary" onClick={onAdd}>
                        <AddCircleOutline />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
