import { Grid, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export const PanelFieldSectionInline = ({
    title,
    labelColumns = 3,
    inputColumns = 9,
    indentation = false,
    children
}: PropsWithChildren<{ title: string | JSX.Element; labelColumns?: number; inputColumns?: number; indentation?: boolean }>) => (
    <Grid item xs={12} sx={{ marginTop: '0 !important' }}>
        <Grid container alignItems="center">
            {indentation && <Grid item xs={1} />}
            <Grid item xs={labelColumns}>
                <Typography
                    style={{
                        paddingTop: 18,
                        paddingLeft: 25,
                        fontWeight: 600,
                        fontSize: '13px',
                        textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={inputColumns} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                <Grid style={{ paddingTop: 18, paddingLeft: 10 }}>{children}</Grid>
            </Grid>
        </Grid>
    </Grid>
);
