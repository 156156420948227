import { DataGridPro, GridActionsCellItem, gridClasses } from '@mui/x-data-grid-pro';
import { GridLevel } from 'ui-component/grids/GridWithLevels/types';
import { CustomToolbar } from 'ui-component/grids/GridWithLevels/components';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material';

export type ListProps = {
    loading: boolean;
    level?: GridLevel;
};

export const List = ({ loading, level }: ListProps) => {
    if (!level) return <></>;

    const internalRowEdit = level.level === 2;
    const { columns, filteredRows } = level;

    let allColumns = [...(columns || [])];

    if (internalRowEdit) {
        allColumns = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 70,
                align: 'left',
                cellClassName: 'actions',
                renderCell: ({ id, row }) => [<GridActionsCellItem icon={<Edit />} label="Edit" className="textPrimary" color="inherit" />]
            },
            ...allColumns
        ];
    }

    return (
        <StripedDataGrid
            loading={loading}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            columns={allColumns}
            rows={filteredRows || []}
            editMode="row"
            components={{
                Toolbar: CustomToolbar
            }}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            disableSelectionOnClick
            pagination
            checkboxSelection
            rowReordering
        />
    );
};

// TODO: change this component to the new one in ui-component/grids
export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200]
    },
    [`& .MuiDataGrid-row--editing`]: {
        color: '#616161 !important',
        '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
            color: '#9e9e9e !important'
        }
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], .MuiTypography-root': {
                color: '#FFF'
            },
            '@media (hover: none)': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#FFF'
            }
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], .MuiTypography-root': {
                color: '#FFF'
            },
            '&:hover, &.Mui-hovered': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#FFF',
                '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                    color: '#FFF'
                },
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: theme.palette.secondary.dark,
                    color: '#FFF'
                }
            }
        }
    }
}));
