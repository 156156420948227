import { Close, Refresh, SwapVert, SystemUpdateAlt } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';

export const CustomToolbar = () => (
    <GridToolbarContainer sx={{ gap: 0.5 }}>
        <Button
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px 16px'
            })}
            startIcon={<SystemUpdateAlt />}
            size="medium"
            color="primary"
        >
            Import
        </Button>
        <Button
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px 16px'
            })}
            startIcon={<Refresh />}
            size="medium"
            color="primary"
        >
            Refresh Grid
        </Button>
        <Button
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px 16px'
            })}
            startIcon={<SwapVert />}
            size="medium"
            color="primary"
        >
            Sort
            <Close sx={{ fontSize: '15px', ml: 1 }} />
        </Button>
    </GridToolbarContainer>
);
