import { ForwardRefExoticComponent, MouseEvent, RefAttributes, forwardRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, PaletteMode, Typography } from '@mui/material';

// project imports
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { activeForm, activeItem, openDrawer } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// types
import { LinkTarget, NavItemType } from 'types';
import { updateSectionName } from 'store/slices/section';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';

interface NavItemProps {
    item: NavItemType;
    level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const modal = useConfirmationModalContext();
    const navigate = useNavigate();
    // const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const { selectedItem, openedForm } = useSelector((state) => state.menu);

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="20px" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem([item.id!]));
        }
        // eslint-disable-next-line
    }, [pathname]);

    const { drawerOpen } = useSelector((state) => state.menu);

    const onClickItem = (id: string | undefined) => {
        if (!id) return;
        dispatch(openDrawer(!drawerOpen));
        dispatch(activeItem([id]));
        dispatch(updateSectionName({ sectionName: '' }));
        dispatch(activeForm(null));
    };

    const handleClickMenuItem = (e: MouseEvent<HTMLAnchorElement>) => {
        if (openedForm) {
            e.preventDefault();
            handleCloseOrChangeForm('discard_changes_select_form');
        } else onClickItem(item.id);
    };

    const handleCloseOrChangeForm = async (actionType: string) => {
        await modal.showConfirmation({
            title: 'Are you sure you want to discard your changes?',
            titleSx: { fontSize: '20px' },
            content: (
                <Alert
                    variant="filled"
                    icon={false}
                    sx={{
                        bgcolor: '#FB9000',
                        color: 'white',
                        borderRadius: '8px !important',
                        height: '32px',
                        fontSize: '14px',
                        width: 'fit-content',
                        padding: '0 16px !important',
                        fontWeight: 500
                    }}
                >
                    This is an irreversible action and will permanently remove your work.
                </Alert>
            ),
            loadingText: 'Loading',
            forwardButtonText: 'Continue Editing',
            actionButtonText: 'Discard changes',
            onForward: null,
            onAction: async () => {
                if (item.url) navigate(item.url);
                onClickItem(item.id);
            },
            showCheckbox: true,
            sectionTitle: 'forms',
            recordType: '',
            actionType
        });
    };

    const menuSelectedBack = (mode: PaletteMode) =>
        mode === 'dark'
            ? theme.palette.secondary.main + 15
            : 'linear-gradient(98.19deg, rgb(55, 50, 110) 0%, rgb(93, 89, 158) 52.08%, rgb(123, 147, 203) 106.47%)';
    const menuSelected = (mode: PaletteMode) => (mode === 'dark' ? theme.palette.secondary.main : '#FFFFFF');

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={({ palette }) => ({
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
                color: theme.palette.text.primary,
                paddingTop: '10px',
                paddingBottom: '10px',
                '&.Mui-selected': {
                    color: menuSelected(palette.mode),
                    background: menuSelectedBack(palette.mode),
                    '&:hover': {
                        background: menuSelectedBack(palette.mode)
                    },
                    '& .MuiListItemIcon-root': {
                        color: menuSelected(palette.mode)
                    }
                },
                '&:hover': {
                    background: menuSelectedBack(palette.mode),
                    color: menuSelected(palette.mode),
                    '& .MuiListItemIcon-root': {
                        color: menuSelected(palette.mode)
                    }
                }
            })}
            selected={selectedItem?.findIndex((id) => id === item.id) > -1}
            onClick={handleClickMenuItem}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={selectedItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
