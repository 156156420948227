import { yupResolver } from '@hookform/resolvers/yup';
import { HighlightOff, KeyboardArrowDown } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    MenuItem,
    Stack,
    Switch,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { defaultValues, formSchema, normalizeDataToForm } from '../utils';
import { listDataTypeEnum, ListForm, ListObject, RegisterListVariables } from '../types';
import { PanelFieldSectionInline } from 'ui-component/forms/PanelFieldSectionInline';
import { useQuery } from '@apollo/client';
import { IQueryGetData } from 'views/backoffice/CustomObjects/types';
import { QUERY_GET_OBJECTS } from 'graphql/queries/customObjects';

export type FormDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: RegisterListVariables['data']) => Promise<void>;
    initialData?: ListObject;
};

export const FormDialog = ({ open, onClose, onSubmit, initialData }: FormDialogProps) => {
    const theme = useTheme();
    const isEdit = !!initialData;
    const [isLoading, setIsLoading] = useState(false);

    const { loading: loadingObjects, data: dataObjects } = useQuery<IQueryGetData>(QUERY_GET_OBJECTS);

    const objectsOptions = dataObjects?.getObjects || [];

    const {
        control,
        watch,
        setValue,
        reset,
        handleSubmit,
        resetField,
        formState: { errors, isValid }
    } = useForm<ListForm>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
        defaultValues
    });

    const listTypeSelected = watch('listType');
    const isBackofficeManaged = watch('backofficeManaged');

    const handleSubmitForm = async ({ listName, description, enabled, objectDefinitionId, ...data }: ListForm) => {
        const payload: RegisterListVariables['data'] = {
            listName,
            description,
            enabled,
            dataType: data.listType as listDataTypeEnum,
            objectDefinitionId: data.listType === 'Object' ? +(objectDefinitionId || 0) : undefined,
            origin: !isBackofficeManaged ? 'Saas' : 'BackOffice'
        };
        setIsLoading(true);
        await onSubmit(payload);
        onClose();
        setIsLoading(false);
    };

    useEffect(() => {
        if (initialData) return;
        if (isBackofficeManaged) resetField('listType');
    }, [initialData, isBackofficeManaged, resetField, setValue]);

    useEffect(() => {
        if (initialData) return;
        if (listTypeSelected === 'User') setValue('backofficeManaged', false);
    }, [initialData, listTypeSelected, setValue]);

    useEffect(() => {
        if (open && initialData) reset(normalizeDataToForm(initialData));
        else reset(defaultValues);
    }, [initialData, open, reset]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{
                    fontSize: '24px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: 0
                }}
            >
                {isEdit ? 'Edit' : 'Create'} List
                <IconButton onClick={onClose}>
                    <HighlightOff sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <PanelFieldSectionInline title="Name" labelColumns={2} inputColumns={10}>
                        <Controller
                            name="listName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        '& input, & div': {
                                            borderRadius: '12px !important'
                                        }
                                    }}
                                    variant="outlined"
                                    error={!!errors.listName}
                                    helperText={errors.listName?.message}
                                    fullWidth
                                    autoFocus
                                    {...field}
                                    size="small"
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                    <PanelFieldSectionInline title="Description" labelColumns={2} inputColumns={10}>
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        '& input, & div': {
                                            borderRadius: '12px !important'
                                        }
                                    }}
                                    variant="outlined"
                                    error={!!errors.description}
                                    helperText={errors.description?.message}
                                    fullWidth
                                    {...field}
                                    size="small"
                                />
                            )}
                        />
                    </PanelFieldSectionInline>
                    <PanelFieldSectionInline title="Backoffice administrated?" labelColumns={2} inputColumns={10}>
                        <Controller
                            name="backofficeManaged"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormGroup sx={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'çenter' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                {...field}
                                                checked={field.value}
                                                sx={{
                                                    '& .Mui-checked ': {
                                                        '& .MuiSwitch-thumb': {
                                                            backgroundColor: theme.palette.secondary.main
                                                        }
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: field.value ? `${theme.palette.secondary.main} !important` : ''
                                                    }
                                                }}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label={field.value ? 'Yes' : 'No'}
                                    />
                                </FormGroup>
                            )}
                        />
                    </PanelFieldSectionInline>
                    <PanelFieldSectionInline title="List Type" labelColumns={2} inputColumns={10}>
                        <Controller
                            name="listType"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    error={!!errors.listType}
                                    helperText={(errors.listType as unknown as FieldError)?.message}
                                    select
                                    fullWidth
                                    sx={{
                                        '& fieldset': {
                                            border: 'none !important'
                                        },
                                        textAlign: 'left',
                                        '& input, & div': {
                                            borderRadius: '12px !important'
                                        }
                                    }}
                                    {...field}
                                    SelectProps={{
                                        IconComponent: KeyboardArrowDown
                                    }}
                                    size="small"
                                    onChange={(e) => {
                                        setValue('listType', e.target.value as unknown as '' | 'User' | 'Value' | 'Object', {
                                            shouldValidate: true
                                        });
                                    }}
                                >
                                    <MenuItem
                                        sx={{
                                            borderBottom: '1px solid #D9D9D9 !important'
                                        }}
                                        value="User"
                                    >
                                        User List
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            borderBottom: '1px solid #D9D9D9 !important'
                                        }}
                                        value="Value"
                                    >
                                        Primitive List
                                    </MenuItem>
                                    <MenuItem
                                        sx={{
                                            borderBottom: 'none'
                                        }}
                                        value="Object"
                                    >
                                        Object List
                                    </MenuItem>
                                </TextField>
                            )}
                        />
                    </PanelFieldSectionInline>
                    {listTypeSelected === 'Object' && !loadingObjects && (
                        <PanelFieldSectionInline title="Select Object" labelColumns={2} inputColumns={10}>
                            <Controller
                                name="objectDefinitionId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        error={!!errors.objectDefinitionId}
                                        helperText={(errors.objectDefinitionId as unknown as FieldError)?.message}
                                        select
                                        fullWidth
                                        sx={{
                                            '& fieldset': {
                                                border: 'none !important'
                                            },
                                            textAlign: 'left',
                                            '& input, & div': {
                                                borderRadius: '12px !important'
                                            }
                                        }}
                                        {...field}
                                        SelectProps={{
                                            IconComponent: KeyboardArrowDown,
                                            renderValue: (selected) =>
                                                objectsOptions.find((obj) => +obj.id === +(selected as string))?.name || ''
                                        }}
                                        size="small"
                                        onChange={(e) => {
                                            setValue('objectDefinitionId', e.target.value, {
                                                shouldValidate: true
                                            });
                                        }}
                                    >
                                        {objectsOptions.map(({ id, name }: { id: string; name: string }, index: number) => (
                                            <MenuItem
                                                sx={{
                                                    borderBottom:
                                                        index === objectsOptions.length - 1 ? 'none' : '1px solid #D9D9D9 !important'
                                                }}
                                                value={id}
                                                key={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </PanelFieldSectionInline>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                <Button sx={{ color: theme.palette.error.dark }} onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    color="secondary"
                    disabled={!isValid}
                    onClick={handleSubmit(handleSubmitForm)}
                    size="small"
                >
                    {isEdit ? 'Update' : 'Create'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
