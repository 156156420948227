import { useEffect, useState } from 'react';

export type ResponseType = { loading: false; data: Record<string, any>[] } | { loading: true; data: undefined };

export const GetData = () => {
    const [response, setResponse] = useState<ResponseType>({ loading: true, data: undefined });

    useEffect(() => {
        (async () => {
            setResponse({ loading: true, data: undefined });
            const data: Record<string, any>[] = await new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        {
                            id: 1,
                            name: 'List 1',
                            description: 'This is the list 1',
                            backofficeManaged: true,
                            listType: 'User List',
                            recordTypes: ['Sales Order', 'Bills']
                        },
                        {
                            id: 2,
                            name: 'List 2',
                            description: 'This is the list 2',
                            backofficeManaged: false,
                            listType: 'User List',
                            recordTypes: ['Sales Order', 'Bills']
                        },
                        {
                            id: 3,
                            name: 'List 2',
                            description: 'This is the list 3',
                            backofficeManaged: true,
                            listType: 'Primitive List',
                            recordTypes: ['Sales Order', 'Bills']
                        }
                    ]);
                }, 2000);
            });
            setResponse({ loading: false, data });
        })();
    }, []);

    return response;
};

export const childData = [
    {
        id: 1,
        name: 'Value 1',
        description: 'This is the value 1'
    },
    {
        id: 2,
        name: 'Value 2',
        description: 'This is the value 2'
    },
    {
        id: 3,
        name: 'Value 3',
        description: 'This is the value 3'
    }
];

export const preferenceList = [
    { id: 1, savedName: 'Basic Data' },
    { id: 2, savedName: 'All Data', role: 1 },
    { id: 3, savedName: 'Only important fields' }
];
