export const randomInteger = () => Math.floor(Math.random() * 100000);

/**
 *  Returns only rows that were update base on its id
 *
 * @param currentRows {Array} current state of the rows
 * @param updatedRows {Array} new state of the rows
 */
export const getOnlyUpdatedRows = <T extends Record<string, any>>(currentRows: T[], updatedRows: T[]) => {
    const filteredRows: Record<string, any>[] = [];

    const indexedCurrentRows = currentRows.reduce<Record<string, any>>((acc, el) => ({ ...acc, [el.id as number]: el }), {});
    for (const row of updatedRows) {
        if (JSON.stringify(row) !== JSON.stringify(indexedCurrentRows[row.id as number])) filteredRows.push(row);
    }

    return filteredRows;
};

/**
 * Returns the same row alphabetically sorted by the given field
 *
 * @param rows {Array} rows to be ordered
 * @param fieldToUse {String} key of the row to use to order
 */
export const orderAlphabetical = <T extends Record<string, any>>(rows: T[], fieldToUse: string) =>
    [...rows]
        .sort((a, b) => a[fieldToUse].toLowerCase().localeCompare(b[fieldToUse].toLowerCase()))
        .map((row, idx) => ({ ...row, order: idx + 1 }));
