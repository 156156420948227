import { gql } from '@apollo/client';

export const QUERY_GET_CUSTOM_LISTS = gql`
    query GetLists {
        getLists {
            id
            listName
            origin
            enabled
            dataType
            description
            objectDefinition {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const QUERY_FIND_LIST_VALUES = gql`
    query FindListValues($data: FindListsValuesInput!) {
        findListValues(data: $data) {
            createdAt
            enabled
            id
            linkedTenant {
                id
                name
            }
            userValue {
                id
                name
                firstName
                lastName
            }
            listId {
                id
            }
            order
            updatedAt
            value
            isDefault
            dynamicObjectValue {
                id
                objectValues {
                    tag
                    value
                    objectProperty {
                        id
                        name
                        isDisplayable
                        order
                        dataType
                        enabled
                    }
                }
                objectDefinition {
                    id
                    name
                }
            }
        }
    }
`;
