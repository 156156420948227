import { Dispatch, SetStateAction } from 'react';
import { BreadcrumbsItem, GridLevel } from './types';

/**
 * Generates the list of elements to show in the breadcrumb
 *
 * @param levels {GridLevel[]} actual list of levels
 * @param setter Setter of levels state
 * @param currentLevel {GridLevel}
 */
export const generateBreadcrumbs = (
    levels: GridLevel[],
    setter: Dispatch<SetStateAction<GridLevel[]>>,
    searchCleaner: () => void,
    currentLevel?: GridLevel
) => {
    if (!currentLevel) return [];

    const filteredLevels = levels.filter((el) => el.level <= currentLevel.level);
    const arr: BreadcrumbsItem[] = [];

    for (const level of filteredLevels) {
        arr.push({
            label: level.title,
            onClick: () => {
                searchCleaner();
                setter((prev) => prev.filter((el) => el.level <= level.level).map((el) => ({ ...el, isActive: el.level === level.level })));
            }
        });
    }

    return arr;
};
