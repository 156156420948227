import { gql } from '@apollo/client';

export const QUERY_GET_TENANTS = gql`
    query TenantsQuery {
        getTenants {
            id
            name
            description
            url
            tenantType {
                id
                name
            }
            parent {
                id
                name
            }
            coverageZone {
                id
                name
            }
            enabled
            children {
                id
                name
            }
            users {
                id
                firstName
                lastName
                roles {
                    id
                    role {
                        id
                        name
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const QUERY_GET_TENANT = gql`
    query TenantFind($filters: TenantFindInput!) {
        TenantFind(data: $filters) {
            id
            email
            name
            lastName
            firstName
            enabled
            createdAt
            updatedAt
            users {
                id
                name
            }
        }
    }
`;

export const QUERY_GET_DEFAULT_VALUES_BY_TENANT = gql`
    query FindByTenantIdAndDefaultValues($tenantId: Float!) {
        findByTenantIdAndDefaultValues(tenantId: $tenantId) {
            id
            isDefault
            enabled
            listValues {
                id
                isDefault
            }
        }
    }
`;
