import { UploadFile } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DropzoneRootProps } from 'react-dropzone';

interface UploadFileButtonProps {
    rootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    label: string;
    variant: 'contained' | 'outlined';
    color: 'primary' | 'secondary';
    theme?: any;
}

export const UploadFileButton = ({ rootProps, label, variant, color, theme }: UploadFileButtonProps) => (
    <Button
        {...rootProps({ className: 'dropzone' })}
        sx={{ width: '191px', borderRadius: '8px', borderWidth: '2px', borderColor: theme ? theme.palette.secondary.main : '' }}
        variant={variant}
        color={color}
        startIcon={<UploadFile />}
    >
        {label}
    </Button>
);
