import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { ToggleChip, ToggleChipProps } from '..';
import { Box } from '@mui/material';

/**
 * Adapter for Toggle Chip in DataGridPro v5
 */
export const GridToggleChipAdapter = ({
    value,
    id,
    field,
    trueLabel,
    falseLabel
}: GridRenderEditCellParams & Pick<ToggleChipProps, 'trueLabel' | 'falseLabel'>) => {
    const apiRef = useGridApiContext();

    const handleValueChange = async (newVal: boolean) => {
        await apiRef.current.setEditCellValue({ id, field, value: newVal });
    };

    return (
        <Box sx={{ px: '9px' }}>
            <ToggleChip value={value} onChange={handleValueChange} falseLabel={falseLabel} trueLabel={trueLabel} />
        </Box>
    );
};
