import { gql } from '@apollo/client';

export const MUTATION_SIGN_UP = gql`
    mutation BackOfficeUserSignUp($data: userAuthentication!) {
        BackOfficeUserSignUp(data: $data) {
            id
            firstName
            lastName
            email
            fireBaseId
            enabled
            validated
            BackOfficeAccess
            SaasAccess
            createdAt
            updatedAt
            name
        }
    }
`;

export const MUTATION_LOGIN = gql`
    mutation Mutation {
        BackOfficeUserLogin
    }
`;
