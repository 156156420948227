import { gql } from '@apollo/client';

export const MUTATION_CREATE_OBJECT = gql`
    mutation createObject($data: CreateObjectInput!) {
        createObject(data: $data) {
            id
            name
            description
            enabled
            createdBy {
                id
                name
            }
            updatedBy {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const MUTATION_UPDATE_OBJECT = gql`
    mutation updateObject($data: UpdateObjectInput!) {
        updateObject(data: $data) {
            id
            name
            description
            enabled
            createdBy {
                id
                name
            }
            updatedBy {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const MUTATION_CREATE_OBJECT_PROPERTY = gql`
    mutation CreateObjectProperty($data: CreateObjectPropertyInput!) {
        createObjectProperty(data: $data) {
            id
            name
            enabled
            isRequired
            isDisplayable
            dataType
            order
            createdAt
            createdBy {
                id
                name
            }
            listType {
                id
            }
        }
    }
`;

export const MUTATION_UPDATE_OBJECT_PROPERTY = gql`
    mutation UpdateObjectProperty($data: UpdateObjectPropertyInput!) {
        updateObjectProperty(data: $data) {
            id
            name
            enabled
            isRequired
            isDisplayable
            dataType
            order
            createdAt
            createdBy {
                id
                name
            }
            listType {
                id
            }
        }
    }
`;

export const MUTATION_REGISTER_OR_UPDATE_DYNAMIC_OBJECT = gql`
    mutation RegisterOrUpdateDynamicObject($data: RegisterOrUpdateDynamicObjectsWithValuesInput!) {
        registerOrUpdateDynamicObject(data: $data) {
            id
            objectDefinition {
                id
                name
            }
            objectValues {
                value
                tag
                objectProperty {
                    id
                    name
                }
            }
        }
    }
`;
