/* eslint-disable arrow-body-style */
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import MainCard from 'ui-component/cards/MainCard';
import { GridLevelBreadcrumbs } from 'ui-component/grids/GridWithLevels/components';
import { GridLevel } from 'ui-component/grids/GridWithLevels/types';
import { generateBreadcrumbs } from 'ui-component/grids/GridWithLevels/utils';
import { FormDialog, OptionsBanner, List, StripedDataGrid, TenantsDialog } from './components';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { customListColumns, listValuesColumns } from './assets';
import GridWithInlineEdit from 'ui-component/grids/GridWithInlineEdit';
import { FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { QUERY_FIND_LIST_VALUES, QUERY_GET_CUSTOM_LISTS } from 'graphql/queries/customLists';
import {
    CreateListValue,
    CreateListValueVariables,
    FindListValues,
    FindListValuesVariables,
    GetLists,
    LIST_DATA_TYPE,
    ListObject,
    ListValue,
    RegisterList,
    RegisterListVariables,
    RegisterOrupdateDynamicObject,
    RegisterOrUpdateDynamicObjectsWithValuesPayload,
    RegisterOrUpdateDynamicObjectsWithValuesPayloadData,
    UpdateList,
    UpdateListValue,
    UpdateListValueVariables,
    UpdateListVariables
} from './types';
import {
    MUTATION_CREATE_LIST_VALUE,
    MUTATION_REGISTER_LIST,
    MUTATION_UPDATE_LIST,
    MUTATION_UPDATE_LIST_VALUE
} from 'graphql/mutations/customLists';
import { QUERY_FIND_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import { CustomObjectProperty, FindObjectProperties, FindObjectPropertiesVariables } from '../CustomObjects/types';
import { FormDialogObjectProperties } from './components/FormDialogObjectProperties';
import { MUTATION_REGISTER_OR_UPDATE_DYNAMIC_OBJECT } from 'graphql/mutations/customObjects';
import { ImportFormDialog } from './components/ImportFormDialog';

const CustomList = () => {
    const dispatch = useDispatch();
    const listRef = React.useRef<any>(null);

    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenFormObjectList, setIsOpenFormObjectList] = useState(false);
    const [objectRowData, setObjectRowData] = useState<{ [key: string]: any } | undefined>(undefined);
    const [dynamicObjectValueId, setDynamicObjectValueId] = useState<string | undefined>(undefined);
    const [isOpenTenants, setIsOpenTenants] = useState(false);
    const [isOpenImport, setIsOpenImport] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const searchRef = useRef('');
    searchRef.current = searchInput;
    const [selectedRow, setSelectedRow] = useState<ListObject>();
    const selectedRowRef = useRef<ListObject | undefined>();
    selectedRowRef.current = selectedRow;
    const [selectedValueId, setSelectedValueId] = useState<number>();
    const [objectProperties, setObjectProperties] = useState<CustomObjectProperty[] | undefined>(undefined);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const { loading, data } = useQuery<GetLists>(QUERY_GET_CUSTOM_LISTS);
    const [getListValues] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_FIND_LIST_VALUES, {
        fetchPolicy: 'no-cache'
    });
    const [getProperties] = useLazyQuery<FindObjectProperties, FindObjectPropertiesVariables>(QUERY_FIND_OBJECT_PROPERTIES);
    // List mutations
    const [registerList] = useMutation<RegisterList, RegisterListVariables>(MUTATION_REGISTER_LIST, {
        update(cache, { data: newData }) {
            const existingData = cache.readQuery<GetLists>({ query: QUERY_GET_CUSTOM_LISTS });
            if (existingData && newData) {
                cache.writeQuery<GetLists>({
                    query: QUERY_GET_CUSTOM_LISTS,
                    data: {
                        getLists: [...existingData.getLists, newData.registerList]
                    }
                });
            }
        }
    });

    const [updateList] = useMutation<UpdateList, UpdateListVariables>(MUTATION_UPDATE_LIST, {
        update(cache, { data: newData }) {
            const existingData = cache.readQuery<GetLists>({ query: QUERY_GET_CUSTOM_LISTS });
            if (existingData && newData) {
                cache.writeQuery<GetLists>({
                    query: QUERY_GET_CUSTOM_LISTS,
                    data: {
                        getLists: existingData.getLists.map((list) => (list.id === newData.updateList.id ? newData.updateList : list))
                    }
                });
            }
        }
    });

    const [registerOrUpdateDynamicObject] = useMutation<RegisterOrupdateDynamicObject, RegisterOrUpdateDynamicObjectsWithValuesPayload>(
        MUTATION_REGISTER_OR_UPDATE_DYNAMIC_OBJECT
    );

    // List values mutations
    const [registerListValue] = useMutation<CreateListValue, CreateListValueVariables>(MUTATION_CREATE_LIST_VALUE);
    const [updateListValue] = useMutation<UpdateListValue, UpdateListValueVariables>(MUTATION_UPDATE_LIST_VALUE);

    const refetchListValues = async () => {
        if (selectedRow) {
            const values = await getListValues({ variables: { data: { listId: Number(selectedRow?.id) } } });
            let objectPropertiesGetted: CustomObjectProperty[] = [];
            if (selectedRow?.objectDefinition) {
                const { data: propertiesData } = await getProperties({
                    variables: { data: { objectDefinitionIds: [Number(selectedRow?.objectDefinition.id)] } }
                });
                if (propertiesData) {
                    const { findObjectProperty } = propertiesData;
                    setObjectProperties([...findObjectProperty].sort((a, b) => a.order - b.order) || []);
                    objectPropertiesGetted = [...findObjectProperty].sort((a, b) => a.order - b.order) || [];
                }
            }
            let filteredValuesRows = [...(values?.data?.findListValues || [])];
            if (selectedRow?.objectDefinition) {
                filteredValuesRows = filteredValuesRows.filter((checkingRow) => {
                    const propertiesValues =
                        checkingRow.dynamicObjectValue?.objectValues
                            .filter((item: any) => item.objectProperty.isDisplayable)
                            .sort((a: any, b: any) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0)) || [];
                    return propertiesValues
                        .map((item: any) => {
                            if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                            if (item.objectProperty.dataType === 'datetime') return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                            return item.value;
                        })
                        .join(', ')
                        .toLowerCase()
                        .includes(searchRef.current.toLowerCase());
                });
            } else {
                filteredValuesRows = filteredValuesRows.filter((row) => row.value.toLowerCase().includes(searchRef.current.toLowerCase()));
            }
            setLevels([
                levels[0],
                {
                    title: selectedRow?.listName ?? '',
                    level: 2,
                    columns: listValuesColumns(handleToggleStatus(selectedRow), handleShowTenants, objectPropertiesGetted),
                    isActive: true,
                    rows: values?.data?.findListValues || [],
                    filteredRows: filteredValuesRows
                }
            ]);
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchInput(newVal);
        if (!currentLevel?.rows) return;

        if (currentLevel.level === 1) {
            const filteredRows = currentLevel.rows.filter((row) => row.listName.toLowerCase().includes(newVal.toLowerCase()));
            setLevels((prev) => prev.map((level) => (level.level === 1 ? { ...level, filteredRows } : level)));
        }

        if (currentLevel.level === 2) {
            if (selectedRow?.objectDefinition) {
                const filteredRows = currentLevel.rows.filter((checkingRow) => {
                    const propertiesValues = checkingRow.dynamicObjectValue.objectValues
                        .filter((item: any) => item.objectProperty.isDisplayable)
                        .sort((a: any, b: any) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0));
                    return propertiesValues
                        .map((item: any) => {
                            if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                            if (item.objectProperty.dataType === 'datetime') return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                            return item.value;
                        })
                        .join(', ')
                        .toLowerCase()
                        .includes(newVal.toLowerCase());
                });
                setLevels((prev) => prev.map((level) => (level.level === 2 ? { ...level, filteredRows } : level)));
            } else {
                const filteredRows = currentLevel.rows.filter((row) => row.value.toLowerCase().includes(newVal.toLowerCase()));
                setLevels((prev) => prev.map((level) => (level.level === 2 ? { ...level, filteredRows } : level)));
            }
        }
    };

    const handleToggleStatus = useCallback(
        (row: ListObject) =>
            async ({ id, enabled }: ListValue) => {
                try {
                    const { data: updatedData } = await updateListValue({
                        variables: { data: { id: Number(id), listId: Number(row.id), enabled: !enabled } }
                    });
                    if (!updatedData) return;

                    setLevels((prev) => [
                        ...prev.map((el) => {
                            if (el.level === 1) {
                                const filteredRows = el.rows ? [...el.rows] : [];
                                return { ...el, filteredRows, rows: el.rows, isActive: false };
                            }
                            let filteredValuesRows = [
                                ...(el.rows?.map((value) =>
                                    value.id === id ? { ...value, enabled: updatedData.updateListValue.enabled } : value
                                ) || [])
                            ];
                            if (selectedRowRef.current?.objectDefinition) {
                                filteredValuesRows = filteredValuesRows.filter((checkingRow) => {
                                    const propertiesValues =
                                        checkingRow.dynamicObjectValue?.objectValues
                                            .filter((item: any) => item.objectProperty.isDisplayable)
                                            .sort((a: any, b: any) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0)) ||
                                        [];
                                    const beta = propertiesValues
                                        .map((item: any) => {
                                            if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                                            if (item.objectProperty.dataType === 'datetime')
                                                return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                                            return item.value;
                                        })
                                        .join(', ')
                                        .toLowerCase();

                                    return beta.includes(searchRef.current.toLowerCase());
                                });
                            } else {
                                filteredValuesRows = filteredValuesRows.filter((checkingRow) =>
                                    checkingRow.value.toLowerCase().includes(searchRef.current.toLowerCase())
                                );
                            }
                            return {
                                ...el,
                                filteredRows: filteredValuesRows,
                                rows:
                                    el.rows?.map((value) =>
                                        value.id === id ? { ...value, enabled: updatedData.updateListValue.enabled } : value
                                    ) || []
                            };
                        })
                    ]);

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Status updated',
                            variant: 'alert',
                            alert: { color: 'success' },
                            close: true
                        })
                    );
                } catch (err: any) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: `Status updated failed: ${err.message || 'Internal server error.'}`,
                            variant: 'alert',
                            alert: { color: 'error' },
                            close: true
                        })
                    );
                }
            },
        [dispatch, updateListValue]
    );

    const handleEnterRow = (row: ListObject) => async () => {
        const values = await getListValues({ variables: { data: { listId: Number(row.id) } } });
        let objectPropertiesGetted: CustomObjectProperty[] = [];
        if (row.objectDefinition) {
            const { data: propertiesData } = await getProperties({
                variables: { data: { objectDefinitionIds: [Number(row.objectDefinition.id)] } }
            });
            if (propertiesData) {
                const { findObjectProperty } = propertiesData;
                setObjectProperties([...findObjectProperty].sort((a, b) => a.order - b.order) || []);
                objectPropertiesGetted = [...findObjectProperty].sort((a, b) => a.order - b.order) || [];
            }
        } else {
            setObjectProperties([]);
        }
        setSearchInput('');
        setSelectedRow(row);
        setLevels((prev) => [
            ...prev.map((el) => {
                const filteredRows = el.rows ? [...el.rows] : [];
                return { ...el, filteredRows, rows: el.rows, isActive: false };
            }),
            {
                title: row.listName,
                level: 2,
                columns: listValuesColumns(handleToggleStatus(row), handleShowTenants, objectPropertiesGetted),
                isActive: true,
                rows: values?.data?.findListValues || [],
                filteredRows: values?.data?.findListValues || []
            }
        ]);
    };

    const handleClickEdit = useCallback(
        (row: ListObject) => () => {
            setSelectedRow(row);
            setIsOpenForm(true);
        },
        []
    );

    const searchBarCleaner = () => {
        setSearchInput('');
    };

    const [levels, setLevels] = useState<GridLevel[]>([
        {
            level: 1,
            title: 'Custom Lists',
            columns: customListColumns(handleClickEdit, handleEnterRow),
            isActive: true,
            rows: [],
            filteredRows: []
        }
    ]);
    const currentLevel = useMemo(() => levels.find((level) => level.isActive), [levels]);
    const breadcrumbList = useMemo(() => generateBreadcrumbs(levels, setLevels, searchBarCleaner, currentLevel), [currentLevel, levels]);

    const handleClickAdd = () => {
        if (currentLevel?.level === 1) setIsOpenForm(true);
        if (currentLevel?.level === 2) {
            if (selectedRow?.objectDefinition) {
                setIsOpenFormObjectList(true);
            } else {
                listRef.current?.handleAddClick();
            }
        }
    };

    const handleSubmitForm = async (list: RegisterListVariables['data']) => {
        if (selectedRow) updateList({ variables: { data: { ...list, id: Number(selectedRow.id) } } });
        else await registerList({ variables: { data: list } });

        dispatch(
            openSnackbar({
                open: true,
                message: selectedRow ? 'List updated' : 'List created',
                variant: 'alert',
                alert: { color: 'success' },
                close: true
            })
        );
    };

    const handleCloseForm = () => {
        setSelectedRow(undefined);
        setIsOpenForm(false);
    };

    const handleSubmitFormObjectList = async (payload: RegisterOrUpdateDynamicObjectsWithValuesPayloadData, listValueStatus?: boolean) => {
        const isEdit = !!dynamicObjectValueId;
        const fullPayload = dynamicObjectValueId ? { ...payload, id: +dynamicObjectValueId } : { ...payload };

        try {
            const res = await registerOrUpdateDynamicObject({
                variables: {
                    data: fullPayload
                }
            });
            if (isEdit) {
                await updateListValue({
                    variables: {
                        data: {
                            id: +(objectRowData?.rowId || 0),
                            listId: +(selectedRow?.id || 0),
                            value: 'object',
                            dynamicObjectValue: +(res.data?.registerOrUpdateDynamicObject.id || 0),
                            enabled: listValueStatus,
                            order: 1
                        }
                    }
                });
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Value updated',
                        variant: 'alert',
                        alert: { color: 'success' },
                        close: true
                    })
                );
            } else {
                await registerListValue({
                    variables: {
                        data: {
                            listId: +(selectedRow?.id || 0),
                            value: 'object',
                            dynamicObjectValue: +(res.data?.registerOrUpdateDynamicObject.id || 0),
                            enabled: true,
                            order: 1
                        }
                    }
                });
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Value created',
                        variant: 'alert',
                        alert: { color: 'success' },
                        close: true
                    })
                );
            }
        } catch (error: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: error.message || 'Internal server error.',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }

        await refetchListValues();
    };

    const handleImportFormObjectList = async (rows: any[]) => {
        for await (const row of rows) {
            const res = await registerOrUpdateDynamicObject({
                variables: {
                    data: row
                }
            });
            if (!res.data?.registerOrUpdateDynamicObject) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error importing values',
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: true
                    })
                );
                return;
            }
            const valueRes = await registerListValue({
                variables: {
                    data: {
                        listId: +(selectedRow?.id || 0),
                        value: 'object',
                        dynamicObjectValue: +(res.data?.registerOrUpdateDynamicObject.id || 0),
                        enabled: true,
                        order: 1
                    }
                }
            });
            if (!valueRes.data?.createListValue) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error importing values',
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: true
                    })
                );
                return;
            }
        }
        await refetchListValues();
        setIsOpenImport(false);
        dispatch(
            openSnackbar({
                open: true,
                message: 'Success!',
                subtitle: 'The document has been imported',
                variant: 'alert',
                alert: { color: 'success' },
                close: true
            })
        );
    };

    const handleCloseFormObjectList = () => {
        setIsOpenFormObjectList(false);
        setDynamicObjectValueId(undefined);
        setObjectRowData(undefined);
    };

    const handleSubmitValuesForm = async (newData: ListValue, isEdit: boolean) => {
        let res: any;
        const payload: CreateListValueVariables['data'] = {
            enabled: newData.enabled,
            value: newData.value,
            listId: Number(selectedRow?.id),
            order: isEdit ? newData.order : Number(newData.id),
            isDefault: newData.isDefault || false
        };

        if (isEdit) {
            res = await updateListValue({ variables: { data: { ...payload, id: Number(newData.id) } } });
            return res.data?.updateListValue;
        }

        res = await registerListValue({ variables: { data: { ...payload, enabled: true, order: 1 } } });
        return res.data?.createListValue;
    };

    const handleSubmitImportValues = async (rows: Pick<ListValue, 'enabled' | 'value' | 'order'>[]) => {
        for await (const newData of rows) {
            const payload: CreateListValueVariables['data'] = {
                enabled: newData.enabled,
                value: newData.value,
                listId: Number(selectedRow?.id),
                order: newData.order
            };
            const res = await registerListValue({ variables: { data: { ...payload, enabled: true, order: 1 } } });
            if (!res.data?.createListValue) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error importing values',
                        variant: 'alert',
                        alert: { color: 'error' },
                        close: true
                    })
                );
                return;
            }
        }
        dispatch(
            openSnackbar({
                open: true,
                message: 'Success!',
                subtitle: 'The document has been imported',
                variant: 'alert',
                alert: { color: 'success' },
                close: true
            })
        );
        await refetchListValues();
        setIsOpenImport(false);
    };

    const handleUpdateValuesOrder = async (updatedOrderList: Record<string, any>[]) => {
        try {
            for await (const row of updatedOrderList as ListValue[]) {
                await updateListValue({ variables: { data: { listId: Number(selectedRow?.id), id: Number(row.id), order: row.order } } });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleShowTenants = (row: ListValue) => {
        if (row.isNew) return;
        setSelectedValueId(Number(row.id));
        setSelectedTenants(row.linkedTenant?.map((tenant) => tenant.id) || []);
        setIsOpenTenants(true);
    };

    const handleCloseTenantDialog = () => {
        setSelectedValueId(undefined);
        setSelectedTenants([]);
        setIsOpenTenants(false);
    };

    const handleSubmitTenants =
        (link = true) =>
        async (tenantsData: number[]) => {
            let updateData: FetchResult<UpdateListValue, Record<string, any>, Record<string, any>>;
            if (link) {
                updateData = await updateListValue({
                    variables: { data: { id: Number(selectedValueId), listId: Number(selectedRow?.id), listLinkedTenants: tenantsData } }
                });
            } else {
                updateData = await updateListValue({
                    variables: { data: { id: Number(selectedValueId), listId: Number(selectedRow?.id), listLinkedTenants: [] } }
                });
            }
            if (updateData) {
                await refetchListValues();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Tenants list updated!',
                        variant: 'alert',
                        alert: { color: 'success' },
                        close: true
                    })
                );
            }
        };

    useEffect(() => {
        if (data)
            setLevels((prev) => [
                {
                    ...prev[0],
                    rows: data.getLists || [],
                    filteredRows: data.getLists || []
                }
            ]);
    }, [data]);

    useEffect(() => {
        if (currentLevel?.level === 1) setSelectedRow(undefined);
    }, [currentLevel]);

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <MainCard
                title={<GridLevelBreadcrumbs items={breadcrumbList} />}
                content={false}
                style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', borderColor: 'white' }}
            >
                <OptionsBanner
                    inputValue={searchInput}
                    name={currentLevel?.title}
                    onSearchChange={handleSearchChange}
                    onAdd={handleClickAdd}
                    showAdd
                />
                {currentLevel?.level === 1 && <List key={currentLevel?.level} level={currentLevel} loading={loading} />}
                {currentLevel?.level === 2 && (
                    <GridWithInlineEdit
                        ref={listRef}
                        GridComponent={StripedDataGrid}
                        loading={loading}
                        initialRows={currentLevel.filteredRows || []}
                        columns={currentLevel.columns || []}
                        onCreate={(val) => handleSubmitValuesForm(val as ListValue, false)}
                        onUpdate={(val) => handleSubmitValuesForm(val as ListValue, true)}
                        onUpdateOrder={handleUpdateValuesOrder}
                        selectedRow={selectedRow}
                        handleOpenFormObjectList={(rowData: { [key: string]: any }, dynamicObjectId: string) => {
                            setIsOpenFormObjectList(true);
                            setObjectRowData(rowData);
                            setDynamicObjectValueId(dynamicObjectId);
                        }}
                        handleShowImportDialog={
                            selectedRow?.dataType === LIST_DATA_TYPE.Object || selectedRow?.dataType === LIST_DATA_TYPE.Value
                                ? () => setIsOpenImport(true)
                                : undefined
                        }
                    />
                )}
            </MainCard>
            <TenantsDialog
                open={isOpenTenants}
                value={selectedTenants}
                onClose={handleCloseTenantDialog}
                onLinkTenants={handleSubmitTenants(true)}
                onUnlinkAllTenants={handleSubmitTenants(false)}
            />
            <FormDialog open={isOpenForm} onClose={handleCloseForm} onSubmit={handleSubmitForm} initialData={selectedRow} />
            <FormDialogObjectProperties
                properties={objectProperties || []}
                open={isOpenFormObjectList}
                onClose={handleCloseFormObjectList}
                onSubmit={handleSubmitFormObjectList}
                initialData={objectRowData}
            />
            <ImportFormDialog
                open={isOpenImport}
                onClose={() => setIsOpenImport(false)}
                isListObject={selectedRow?.dataType === LIST_DATA_TYPE.Object}
                objectProperties={objectProperties}
                columns={currentLevel?.columns || []}
                onSubmitObject={handleImportFormObjectList}
                onSubmitPrimitive={handleSubmitImportValues}
                selectedRow={selectedRow}
            />
        </div>
    );
};

export default CustomList;
